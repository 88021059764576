// BPS:
// 2 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const sectionHeight = {
  list:         [8, 8, 8, 10, 7, 5, 5, 7, 7],
};

export const list = {
  intro: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        [4, 4, 4, 5, 3, 2, 2, 4, 4],
    left:       [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth:   [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight:  [2, 2, 2, 3, 2, 2, 2, 2, 2],
  },
};
