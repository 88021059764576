import * as React from 'react';
import PageEnhancer, { EntryPageProps } from '../../components/PageEnhancer';
import { Header, PageLayout } from '../../components';
import { GridSection, GridBox } from '../../components/Grid';
import { Heading1, Heading3, Text } from '../../helper/Typography';
import { Link } from 'gatsby';
import {
  getUrlFromId,
  filterArticlesAndCaseStudiesByType,
} from '../../helper/helperServices';
import { sectionHeight, list } from './layouts';
import { SeoDataComponent } from '@lws/react-components';
import { mapMetaObject } from '../../helper/helperServices';

class Articles extends PageEnhancer {
  constructor(props: EntryPageProps) {
    super(props);
    this.state = {
      darkTheme: false,
      isScreenDesktop: true,
    };
  }

  render() {
    const {
      generalTexts,
      routeTexts: articles,
      locale,
      node_locale,
    } = this.props.pageContext;
    const { pathname } = this.props.location;

    return (
      <PageLayout
        userLanguage={locale}
        generalText={generalTexts}
        darkTheme={this.state.darkTheme}
      >
        <main className={this.state.darkTheme ? 'App Dark' : 'App Light'}>
          <Header
            pathname={pathname}
            darkTheme={this.state.darkTheme}
            generalTexts={generalTexts}
            lang={locale}
          />
          <SeoDataComponent data={mapMetaObject(articles.meta).data} />
          <GridSection gridRows={sectionHeight.list}>
            <GridBox layout={list.intro} style={{ alignContent: 'start' }}>
              <Heading1>{articles.allTitle}</Heading1>
              {filterArticlesAndCaseStudiesByType('article')
                .filter(e => e.locale === node_locale)
                .map(e => (
                  <Link
                    key={e.slug}
                    to={`${getUrlFromId(locale, 'articles')}/${e.slug}`}
                  >
                    <Heading3>{e.title}</Heading3>
                    <Text.pGrey>
                      {generalTexts.readingTime} {e.readingTime} Min
                    </Text.pGrey>
                  </Link>
                ))}
            </GridBox>
          </GridSection>
        </main>
      </PageLayout>
    );
  }
}

export default Articles;
